import React from "react"
import Icon from "@mdi/react";

function LabelWithIcon(props) {

    return (
        <div className="flex">
            <Icon path={props.icon} size={0.75} />
            <div className="is-size-5 ml-2">{props.text}</div>
        </div>
    )

}

export default LabelWithIcon