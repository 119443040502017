import React from "react"
import Chip from "../chip"
import ModalComponent from "../modal"

const YearsPanel = (props) => {

    return (
        <div className="categoriesPannel">
            <ModalComponent showModal={props.showModal} onClose={props.onClose} title="Tags">
                <div className="columns is-multiline categories-list 
                            is-mobile is-variable is-1">

                    {props.years.map(year => (
                        <Chip key={year} name={year} isActive={year === props.chosenYear}
                            color="light" slug={year}
                            onClick={props.filterByYear} />
                    ))}
                </div>
            </ModalComponent>
        </div>
    )
}

export default YearsPanel
