import { mdiChevronDown } from "@mdi/js"
import Icon from "@mdi/react"
import React, { useState } from "react"
import CategoriesPannel from "./categories-pannel"
import Chip from "./chip"

function TagsList(props) {

    const [categoriesPannelIsOpened, setCategoriesPannel] = useState(false);

    function toggleCategoriesPannel() {
        setCategoriesPannel(categoriesPannelIsOpened => !categoriesPannelIsOpened);
    }

    return (
        <div className="column categories-wrapper mt-3">
            <div className="columns is-mobile is-variable is-1">
                <div className="column columns categories-list is-mobile is-variable is-1 pb-0">
                    <div className="column is-narrow" style={{ margin: 'auto 0' }}>
                        <div className="title is-3 pr-2">{props.title}</div>
                    </div>
                    <Chip name="All News" isActive={!props.activeCategory}
                        isLarge={true} onClick={props.filterByCategory} color="light" />
                    {props.categories.map(category => (
                        <Chip name={category.name} isActive={props.activeCategory === category.slug}
                            slug={category.slug} isLarge={true} onClick={props.filterByCategory} color="light" />
                    ))}

                </div>
                <div className="column dropdown-button p-0">
                    <span className="icon" onClick={toggleCategoriesPannel}>
                        <Icon path={mdiChevronDown} size={1} />
                    </span>
                </div>
                <CategoriesPannel chosenCategory={props.activeCategory} categories={props.categories}
                    onClose={toggleCategoriesPannel} showModal={categoriesPannelIsOpened}
                    filterByCategory={props.filterByCategory} defaultCategoryName="All News" />
            </div>
        </div>

    )
}

export default TagsList