import { mdiChevronDown } from "@mdi/js"
import Icon from "@mdi/react"
import React, { useState } from "react"
import YearsPanel from "./years-panel"

function YearFilter(props) {

    const [yearsPanelIsOpened, setYearsPannel] = useState(false);

    function toggleCategoriesPannel() {
        setYearsPannel(yearsPanelIsOpened => !yearsPanelIsOpened);
    }

    return (
        <div className="columns year-filter">
            <div className="column categories-wrapper pb-0">
                <div className="columns is-mobile is-variable is-1">
                    <div className="column columns categories-list is-mobile is-variable is-1 pb-0">
                        <div className="column is-narrow">
                            <div className="title is-2">{props.name}</div>
                        </div>
                        {props.years.map(year => (
                            <div className="column is-narrow">
                                <div key={year} className={`title year is-2 ${props.activeYear === year ? 'selected-year' : ''}`}
                                    onClick={() => props.filterByYear(year)}>
                                    {year}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="column dropdown-button p-0">
                        <span className="icon" onClick={toggleCategoriesPannel}>
                            <Icon path={mdiChevronDown} size={1} />
                        </span>
                    </div>
                    <YearsPanel chosenYear={props.activeYear} years={props.years}
                        onClose={toggleCategoriesPannel} showModal={yearsPanelIsOpened}
                        filterByYear={props.filterByYear} />
                </div>
            </div>
        </div>
    )
}

export default YearFilter