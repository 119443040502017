import React from "react"
import LinkWithIcon from "../link-with-icon"
import { mdiArrowLeftThick } from '@mdi/js'
import { mdiAccountEdit } from '@mdi/js'
import PublicationDate from "../publication-date"
import { navigate } from '@reach/router';
import Chip from "../chip"
import LabelWithIcon from "../label-with-icon"

function NewsUnitHeader(props) {

    function openNewsWithTag(tag) {
        navigate('news?tag=' + tag);
    }

    function openNewsWithCategory(category) {
        navigate('news?category=' + category);
    }

    return (
        <div className="news-unit-header block">
            <div className="columns">
                {props.newsUnit.articles_category &&
                    <Chip onClick={openNewsWithCategory} isLarge={true} isActive={true}
                        name={props.newsUnit.articles_category.name} slug={props.newsUnit.articles_category.slug} />}
                {!!props.newsUnit.article_tags.length &&
                    <div className="column is-narrow tags">
                        <div className="columns is-mobile">
                            {props.newsUnit.article_tags.map(tag => (
                                <Chip onClick={openNewsWithTag} color="white" slug={tag.slug} name={tag.name} />
                            ))}
                        </div>
                    </div>}

            </div>
            <LinkWithIcon link="/news" icon={mdiArrowLeftThick} name={props.newsUnit.title} size="is-2" />
            <div className="flex">
                {props.newsUnit.author &&
                    <div className="mr-5">
                        <LabelWithIcon icon={mdiAccountEdit} text={`By: ${props.newsUnit.author}`} />
                    </div>}
                {props.newsUnit.creationTime && <PublicationDate date={props.newsUnit.creationTime} />}
            </div>
        </div>
    )
}

export default NewsUnitHeader