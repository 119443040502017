import React from "react"
import { Link } from 'gatsby'
import Img from "gatsby-image"
import PublicationDate from "../publication-date"
import { mdiClockTimeEightOutline } from "@mdi/js"
import LabelWithIcon from "../label-with-icon"
import ReactMarkdown from "react-markdown"
import readingTime from 'reading-time'

function NewsUnitThumb(props) {

    const titleClass = `title mb-3 ${props.titleClass}`;
    const contentClass = `news-content ${props.contentClass}`
    const unitThumbClass = `news-unit-thumb-block ${props.isLarge ? 'px-3' : ''} 
         ${props.classes ? props.classes : 'mb-6'}`;

    const minutesRead = Math.ceil(readingTime(props.newsUnit.content).minutes);

    return (

        <div className={unitThumbClass}>
            <Link className="news-unit-thumb" to={`/${props.newsUnit.id}`}>
                <div className="columns is-mobile block">
                    <div className="image column is-narrow">
                        <Img fluid={props.newsUnit.image.childImageSharp.fluid} />
                    </div>
                    <div className="column">
                        <div className={titleClass}>{props.newsUnit.title}</div>
                        <div className={contentClass}>
                            <ReactMarkdown
                                transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.GATSBY_API_URL_PUBLIC}${uri}`}
                                source={props.newsUnit.content?.substring(0, 150)} />
                        </div>
                        <div className="flex mt-4">
                            {props.newsUnit.creationTime && <div className="mr-4">
                                <PublicationDate date={props.newsUnit.creationTime} />
                            </div>}
                            <LabelWithIcon icon={mdiClockTimeEightOutline} text={`${minutesRead} minutes read`} />
                        </div>
                    </div>
                </div>

            </Link >
        </div>

    )
}

export default NewsUnitThumb