import { mdiPrinterPos } from "@mdi/js"
import React from "react"
import NewsUnitThumb from "./news-unit-thumb"

function NewsList(props) {

    const unitThumbClass = props.isLarge ? '' : 'is-one-third column'
    const newsListClass = `news-list is-8 is-variable ${props.isLarge ? '' : 'columns'}`
    const classes = props.classes

    return (
        <div className={classes}>
            {props.title && <div className="title is-3 mb-3 column">{props.title}</div>}
            <div className={newsListClass}>
                {props.news.map(item => (
                    <NewsUnitThumb classes={unitThumbClass} isLarge={props.isLarge}
                        contentClass={props.contentClass} titleClass={props.titleClass} newsUnit={item} />
                ))}
            </div>
        </div>
    )
}

export default NewsList