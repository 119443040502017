import React from "react"
import { mdiUpdate } from '@mdi/js';
import LabelWithIcon from "./label-with-icon";

function PublicationDate(props) {

    const date = new Date(props.date)
    const formattedDate = `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`

    return (
        <LabelWithIcon icon={mdiUpdate} text={formattedDate} />
    )
}

export default PublicationDate