import React from "react"
import Chip from "../chip"
import TagsList from "../tags-list"
import YearFilter from "./year-filter";

const NewsHeader = (props) => {

    return (
        <div className="news-header">
            <YearFilter filterByYear={props.filterByYear} years={props.years} name="Posts by Year"
                activeYear={props.activeYear} />
            <div className="columns">
                <TagsList title="Categories" categories={props.categories} activeCategory={props.activeCategory}
                    filterByCategory={props.filterByCategory} />
            </div>
        </div>
    )
}

export default NewsHeader
